import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from "@ionic/react";
import React, { useEffect } from "react";
import Logic from "../../helpers/Logic";
import "./style.css";
import AdMobService from "../../helpers/AdMobService";
import { App } from "@capacitor/app";
import { useHistory } from "react-router";

const Index: React.FC = () => {
  const ionRouter = useIonRouter();
  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  });

  const widthInputRef = React.useRef<HTMLIonInputElement>(null);
  const heightInputRef = React.useRef<HTMLIonInputElement>(null);
  const aswInputRef = React.useRef<HTMLIonInputElement>(null);
  const ashInputRef = React.useRef<HTMLIonInputElement>(null);
  const selectRef = React.useRef<HTMLIonSelectElement>(null);

  const resultResolutionRef = React.useRef<HTMLIonCardTitleElement>(null);
  const resultASRef = React.useRef<HTMLIonCardTitleElement>(null);
  const resultNumPixelRef = React.useRef<HTMLIonCardTitleElement>(null);

  type AspectRatio = {
    value: string;
    text: string;
  };

  useEffect(() => {
    AdMobService.autoLoadBanner();
    console.log("Ads Auto Reload");
  }, []);

  AdMobService.adjustAppMargin();

  const selectData: AspectRatio[] = [
    { value: "3:2", text: "3:2 - Microsoft Surface, Classic Film" },
    { value: "4:3", text: "4:3 - Classic TV, Monitor, Projector" },
    { value: "16:10", text: "16:10 - Apple MacBook, Creator Laptops" },
    { value: "16:9", text: "16:9 - HD Video, Common Laptop, TV" },
    { value: "21:9", text: "21:9 - Ultrawide Monitor, Cinema Video" },
    { value: "32:9", text: "32:9 - Ultrawide Monitor, Double 16:9 Display" },
  ];

  const selectOptions = selectData.map((item) => (
    <IonSelectOption key={item.value} value={item.value}>
      {item.text}
    </IonSelectOption>
  ));

  const widthChangeHandler = () => {
    if (!widthInputRef.current) return;
    const value = widthInputRef.current!.value;
    if (!value) return;
    if (+value <= 0) return;

    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;
    const newHeight = Logic.calculateHeight(+value, +enteredASWidth, +enteredASHeight);
    heightInputRef.current!.value = newHeight;

    // setResState(res => ({ ...res, h: newHeight }));
  };

  const heightChangeHandler = () => {
    const value = heightInputRef.current!.value;
    if (!value) return;
    if (+value <= 0) return;

    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;
    const newWidth = Logic.calculateWidth(+value, +enteredASWidth, +enteredASHeight);
    widthInputRef.current!.value = newWidth;

    // setResState(res => ({ ...res, w: newWidth }));
  };

  const aswChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;

    const enteredHeight = heightInputRef.current!.value!;
    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;

    const newWidth = (+enteredHeight / +enteredASHeight) * +enteredASWidth;
    widthInputRef.current!.value = newWidth;

    const aspectRatio = Logic.calculateAspectRatio(+enteredASWidth, +enteredASHeight);
    resultResolutionRef.current!.innerText = aspectRatio.width + ":" + aspectRatio.height;

    updateSelectValue();
  };

  const ashChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;

    const enteredWidth = widthInputRef.current!.value!;
    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;

    const newHeight = (+enteredWidth / +enteredASWidth) * +enteredASHeight;
    heightInputRef.current!.value = newHeight;

    const aspectRatio = Logic.calculateAspectRatio(+enteredASWidth, +enteredASHeight);
    resultResolutionRef.current!.innerText = aspectRatio.width + ":" + aspectRatio.height;

    updateSelectValue();
  };

  const updateSelectValue = () => {
    const enteredASWidth = aswInputRef.current!.value;
    const enteredASHeight = ashInputRef.current!.value;

    if (!enteredASHeight || !enteredASWidth) return;

    selectRef.current!.value = enteredASWidth + ":" + enteredASHeight;
  };

  const history = useHistory();

  const moreInfo = () => {
    if (!aswInputRef.current!.value || !ashInputRef.current!.value) return;
    const enteredASWidth = aswInputRef.current!.value;
    const enteredASHeight = ashInputRef.current!.value;
    history.push(`/apps/as/${enteredASWidth}/${enteredASHeight}`);
  };

  const displayChangeHandler = (value: string) => {
    if (value === "0:0") return;
    if (value !== "") {
      const [width, height] = value.split(":");
      if (aswInputRef.current) aswInputRef.current.value = width;
      if (ashInputRef.current) ashInputRef.current.value = height;

      widthChangeHandler();
    }
  };

  const resultUpdateHandler = () => {
    const enteredWidth = widthInputRef.current!.value;
    const enteredHeight = heightInputRef.current!.value;
    const enteredASWidth = aswInputRef.current!.value;
    const enteredASHeight = ashInputRef.current!.value;

    if (!enteredWidth || !enteredHeight || !enteredASWidth || !enteredASHeight) return;

    resultResolutionRef.current!.innerText = (+enteredWidth).toFixed(0) + " x " + (+enteredHeight).toFixed(0);
    resultASRef.current!.innerText = enteredASWidth + ":" + enteredASHeight;

    const pixels = +enteredWidth * +enteredHeight;
    resultNumPixelRef.current!.innerText = Math.round(pixels).toLocaleString("en-US");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Find Resolutions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid fixed={true}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Common Aspect Ratios</IonLabel>
                      <IonSelect ref={selectRef} placeholder="Custom" className="w-100" value="16:9" onIonChange={(e) => displayChangeHandler(e.detail.value)}>
                        {selectOptions}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Ratio Width</IonLabel>
                      <IonInput ref={aswInputRef} type="number" value="16" min={1} onIonInput={aswChangeHandler}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Ratio Height</IonLabel>
                      <IonInput ref={ashInputRef} type="number" value="9" min={1} onIonInput={ashChangeHandler} onIonChange={resultUpdateHandler}></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonGrid className="ion-margin-vertical">
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="fixed">Width</IonLabel>
                      <IonInput
                        ref={widthInputRef}
                        type="number"
                        value="1920"
                        min={1}
                        onIonInput={widthChangeHandler}
                        onIonChange={resultUpdateHandler}
                      ></IonInput>
                      <IonLabel slot="end">pixels</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="fixed">Height</IonLabel>
                      <IonInput
                        ref={heightInputRef}
                        type="number"
                        value="1080"
                        min={1}
                        onIonInput={heightChangeHandler}
                        onIonChange={resultUpdateHandler}
                      ></IonInput>
                      <IonLabel slot="end">pixels</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonCard color="medium">
                <IonCardHeader color="primary">
                  <IonTitle color="light">Result</IonTitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonCard className="ion-no-margin ion-text-center">
                          <IonCardHeader>
                            <IonCardTitle ref={resultResolutionRef}>1920 x 1080</IonCardTitle>
                            <IonCardSubtitle>Dimensions</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="ion-text-center">
                        <IonCard className="ion-no-margin">
                          <IonCardHeader>
                            <IonCardTitle ref={resultASRef}>16:9</IonCardTitle>
                            <IonCardSubtitle>Aspect Ratio</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                      <IonCol className="ion-text-center">
                        <IonCard className="ion-no-margin">
                          <IonCardHeader>
                            <IonCardTitle ref={resultNumPixelRef}>2,073,600</IonCardTitle>
                            <IonCardSubtitle>Pixel count</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
              <div className="ion-text-right">
                <IonButton onClick={() => moreInfo()}>More Info</IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Index;
