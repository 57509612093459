import { Browser } from "@capacitor/browser";

module BrowserService {
  const utmSource = "aspect-ratio-calc";
  const site = "calculatorhub.app";

  export function getPrimaryColor() {
    var styles = getComputedStyle(document.body);
    var value = String(styles.getPropertyValue("--ion-color-primary")).trim();
    return value;
  }

  export function showGuide(url: string) {
    url += "/?view_mode=guide&utm_source=" + utmSource + "&utm_campaign=guide";
    show(url);
  }

  export function showHelp(url: string) {
    url +=
      "/?view_mode=explain&utm_source=" + utmSource + "&utm_campaign=explain";
    show(url);
  }

  export async function show(url: string) {
    url = "https://" + site + "/" + url;
    const openURL = async (myurl: string) => {
      await Browser.open({ url: myurl });
    };
    openURL(url);
  }
}

export default BrowserService;
