export const UrlDB: { [key: string]: string } = {
  "16:9": "16-9-aspect-ratio",
  "16:10": "16-10-aspect-ratio",
  "3:2": "3-2-aspect-ratio",
  "32:9": "32-9-aspect-ratio",
  "4:3": "4-3-aspect-ratio",
  "5:4": "5-4-aspect-ratio-calculator",
  
  "21:9": "21-9-aspect-ratio",
  "12:5": "21-9-aspect-ratio",
  "43:18": "21-9-aspect-ratio",
  "64:27": "21-9-aspect-ratio",

  "1:2": "1-2-aspect-ratio-calculator",
  "2:3": "2-3-aspect-ratio-calculator",
  "3:4": "3-4-aspect-ratio-calculator",
  "4:5": "4-5-aspect-ratio-calculator",
  "9:16": "9-16-aspect-ratio-calculator",

  "1:1": "1-1-aspect-ratio-calculator",
};
