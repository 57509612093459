import { RangeValue } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { close, colorPaletteOutline, moon } from "ionicons/icons";
import classes from "./Settings.module.css";
import { useState } from "react";
import myUtil from "../../helpers/cha-utils";

type Props = {
  decimalValue?: number;
  onRangeUpdated?: (value: RangeValue) => void;
  onClose?: () => void;
};

const Settings: React.FC<Props> = (props) => {
  const [darkMode, setDarkMode] = useState<boolean>(localStorage.getItem(myUtil.prefix + "dark-mode-status") === "true");

  const [themeColor, setThemeColor] = useState<string>(myUtil.formatThemeName(localStorage.getItem(myUtil.prefix + "theme-color-class") ?? "default-theme"));

  const themeColorOptionsJSX = Object.entries(myUtil.themeColors).map(([key, item]) => (
    <IonSelectOption key={key} value={key}>
      {myUtil.formatThemeName(key)}
    </IonSelectOption>
  ));

  // const themeColors = ["red", "blue", "pink"];
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.onClose && props.onClose()}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div slot="content" className={`${classes.iac} ion-padding`}>
        {props.decimalValue && (
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-align-center">
              <IonCol>
                <IonList lines="none" className={classes.list}>
                  <IonItem lines="none">
                    <div>
                      <IonLabel>
                        Fraction digits: <strong>{props.decimalValue}</strong>
                      </IonLabel>
                    </div>

                    <IonRange
                      ticks={true}
                      snaps={true}
                      min={0}
                      max={14}
                      value={props.decimalValue}
                      onIonKnobMoveEnd={({ detail }) => props.onRangeUpdated && props.onRangeUpdated(detail.value)}
                    >
                      <IonLabel slot="start">0</IonLabel>
                      <IonLabel slot="end">14</IonLabel>
                    </IonRange>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonGrid fixed={true}>
          <IonRow className="ion-justify-align-center splitLayout">
            <IonCol size="12" sizeMd="6">
              <IonList lines="none" className={classes.list}>
                <IonItem>
                  <IonIcon slot="start" icon={moon}></IonIcon>
                  <IonLabel>
                    Toggle Dark Mode
                    <div>
                      <strong>{darkMode ? "On" : "Off"}</strong>
                    </div>
                  </IonLabel>
                  <IonToggle
                    id="themeToggle"
                    slot="end"
                    checked={darkMode}
                    onIonChange={(e) => {
                      myUtil.toggleDarkTheme(e.detail.checked);
                      setDarkMode(e.detail.checked);
                    }}
                  ></IonToggle>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonList lines="none" className={classes.list}>
                <IonItem>
                  <IonIcon slot="start" icon={colorPaletteOutline}></IonIcon>
                  <IonLabel>
                    Theme color
                    <div>
                      <strong>{themeColor}</strong>
                    </div>
                  </IonLabel>
                  <IonSelect
                    placeholder="Select Theme"
                    className={`${classes["cha-select"]} ion-no-padding`}
                    title="Select Theme"
                    interface="popover"
                    onIonChange={(e) => {
                      myUtil.setupTheme(e.detail.value);
                      console.log(e.detail.value);
                      setThemeColor(myUtil.formatThemeName(e.detail.value));
                    }}
                    // compareWith={compareWith}
                    // value={currentUnit}
                    // onIonChange={(ev) => setCurrentUnit(ev.detail.value)}
                    // onIonChange={unitChangeHandler}
                  >
                    {themeColorOptionsJSX}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};
export default Settings;
