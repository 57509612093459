import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import Logic from "../../helpers/Logic";
import "./style.css";

const Index: React.FC = () => {
  
  const widthInputRef = React.useRef<HTMLIonInputElement>(null);
  const heightInputRef = React.useRef<HTMLIonInputElement>(null);
  const aswInputRef = React.useRef<HTMLIonInputElement>(null);
  const ashInputRef = React.useRef<HTMLIonInputElement>(null);
  const aspectRatioResultRef = React.useRef<HTMLIonCardTitleElement>(null);

  const caculateAspectRatio = () => {
    const enteredWidth = widthInputRef.current!.value!;
    const enteredHeight = heightInputRef.current!.value!;
    const diagnal = Logic.calculateDiagnal(+enteredWidth, +enteredHeight);
    const aspectRatio = Logic.calculateAspectRatio(
      +enteredWidth,
      +enteredHeight
    );
    aswInputRef.current!.value = aspectRatio.width;
    ashInputRef.current!.value = aspectRatio.height;

    aspectRatioResultRef.current!.innerText =
      aspectRatio.width + ":" + aspectRatio.height;

    console.log(diagnal);
    console.log(aspectRatio);
  };

  const widthChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;
    caculateAspectRatio();
  };

  const heightChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;
    caculateAspectRatio();
  };

  const aswChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;

    const enteredHeight = heightInputRef.current!.value!;
    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;

    const newWidth = (+enteredHeight / +enteredASHeight) * +enteredASWidth;
    widthInputRef.current!.value = newWidth;

    const aspectRatio = Logic.calculateAspectRatio(
      +enteredASWidth,
      +enteredASHeight
    );
    aspectRatioResultRef.current!.innerText =
      aspectRatio.width + ":" + aspectRatio.height;
  };

  const ashChangeHandler = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    if (!value) return;
    if (+value <= 0) return;

    const enteredWidth = widthInputRef.current!.value!;
    const enteredASWidth = aswInputRef.current!.value!;
    const enteredASHeight = ashInputRef.current!.value!;

    const newHeight = (+enteredWidth / +enteredASWidth) * +enteredASHeight;
    heightInputRef.current!.value = newHeight;

    const aspectRatio = Logic.calculateAspectRatio(
      +enteredASWidth,
      +enteredASHeight
    );
    aspectRatioResultRef.current!.innerText =
      aspectRatio.width + ":" + aspectRatio.height;
  };

  const displayChangeHandler = (value: string) => {
    if (value !== "") {
      const [width, height] = value.split(":");
      widthInputRef.current!.value = width;
      heightInputRef.current!.value = height;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Find Aspect Ratio</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid fixed={true}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Common Displays</IonLabel>
                      <IonSelect
                        placeholder="Select Display"
                        className="w-100"
                        value="1920:1080"
                        onIonChange={(e) =>
                          displayChangeHandler(e.detail.value)
                        }
                      >
                        <IonSelectOption value="3000:2000">
                          3000 x 2000 (Surface Book)
                        </IonSelectOption>
                        <IonSelectOption value="1920:1080">
                          1920 x 1080 (Full HD)
                        </IonSelectOption>
                        <IonSelectOption value="1280:720">
                          1280 x 720 (HD)
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="fixed">Width</IonLabel>
                      <IonInput
                        ref={widthInputRef}
                        type="number"
                        value="1920"
                        min={1}
                        onIonInput={widthChangeHandler}
                      ></IonInput>
                      <IonLabel>pixels</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="fixed">Height</IonLabel>
                      <IonInput
                        ref={heightInputRef}
                        type="number"
                        value="1080"
                        min={1}
                        onIonInput={heightChangeHandler}
                      ></IonInput>
                      <IonLabel>pixels</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="ion-margin-vertical">
                <IonRow>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Ratio Width</IonLabel>
                      <IonInput
                        ref={aswInputRef}
                        type="number"
                        value="16"
                        min={1}
                        onIonInput={aswChangeHandler}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem fill="outline">
                      <IonLabel position="floating">Ratio Height</IonLabel>
                      <IonInput
                        ref={ashInputRef}
                        type="number"
                        value="9"
                        min={1}
                        onIonInput={ashChangeHandler}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>

            <IonCol size="12" sizeMd="6">
              <IonCard color="medium">
                <IonCardHeader color="primary">
                  <IonTitle color="light">Result</IonTitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonCard className="ion-no-margin ion-text-center">
                          <IonCardHeader>
                            <IonCardTitle ref={aspectRatioResultRef}>
                              16:9
                            </IonCardTitle>
                            <IonCardSubtitle>Aspect Ratio</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Index;
