import { Redirect, Route, matchPath } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  expandOutline,
  layersOutline,
  tabletLandscapeOutline,
} from "ionicons/icons";

import Simple from "./pages/Simple";
import ByAspectRatio from "./pages/ByAspectRatio";
import Advanced from "./pages/Advanced";
import AspectRatio from "./pages/AspectRatio";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/custom.css";
import { IS_EMBED } from "./helpers/Helper";
import myUtil from "./helpers/cha-utils";
import AdMobService from "./helpers/AdMobService";
import { useEffect } from "react";

setupIonicReact();

myUtil.setupDefaultDarkMode();
myUtil.setupDefaultTheme();
myUtil.setupAndroidStatusBar();

const App: React.FC = () => {
  const isMatch = matchPath(window.location.pathname, {
    path: "/apps/as/:asWidth/:asHeight",
  });
  const tabBarVisibility = isMatch === null ? "" : "none";

  console.log(isMatch);
  const tabBarPosition: "bottom" | "top" | undefined = IS_EMBED
    ? "top"
    : "bottom";
  const tabBarColor = IS_EMBED ? "primary" : "";



  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/apps/as/:asWidth/:asHeight" component={AspectRatio} />
            <Route exact path="/by-aspect-ratio" component={ByAspectRatio} />
            <Route exact path="/simple" component={Simple} />
            <Route exact path="/advanced" component={Advanced} />
            <Redirect exact path="/" to="/by-aspect-ratio" />
          </IonRouterOutlet>
          <IonTabBar
            slot={tabBarPosition}
            color={tabBarColor}
            style={{ display: `${tabBarVisibility}` }}
          >
            <IonTabButton tab="by-aspect-ratio" href="/by-aspect-ratio">
              <IonIcon aria-hidden="true" icon={tabletLandscapeOutline} />
              <IonLabel>Find Resolutions</IonLabel>
            </IonTabButton>
            <IonTabButton tab="simple" href="/simple">
              <IonIcon aria-hidden="true" icon={expandOutline} />
              <IonLabel>Find Ratio</IonLabel>
            </IonTabButton>
            <IonTabButton tab="advanced" href="/advanced">
              <IonIcon aria-hidden="true" icon={layersOutline} />
              <IonLabel>More</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
