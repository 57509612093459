import { IonButton, IonIcon } from "@ionic/react";
import React from "react";
import BrowserService from "../helpers/BrowserService";
import { helpCircle, informationCircle } from "ionicons/icons";

type Props = {
  name: string;
};
export const ButtonExplain: React.FC<Props> = (props) => {
  return (
    <div className="ion-text-right ion-padding-top">
      <IonButton onClick={() => BrowserService.showHelp(props.name)} shape="round" size="small">
        <IonIcon icon={informationCircle} slot="start" /> Explanation
      </IonButton>
    </div>
  );
};
