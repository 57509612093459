import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { settingsOutline, square, tabletLandscape, tabletPortrait, tvOutline } from "ionicons/icons";
import Settings from "../../components/Footer/Settings";
import { useRef } from "react";

const Index: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>More</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={settingsOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel>Square</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/1/1">
            <IonIcon icon={square} slot="start"></IonIcon>
            <IonLabel>1:1 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Landscape</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/3/2">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>3:2 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/4/3">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>4:3 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/5/4">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>5:4 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/16/9">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>16:9 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/16/10">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>16:10 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/21/9">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>21:9 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/32/9">
            <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
            <IonLabel>32:9 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Portrait</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/1/2">
            <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
            <IonLabel>1:2 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/2/3">
            <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
            <IonLabel>2:3 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/3/4">
            <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
            <IonLabel>3:4 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/4/5">
            <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
            <IonLabel>4:5 Aspect Ratio Calculator</IonLabel>
          </IonItem>
          <IonItem routerLink="/apps/as/9/16">
            <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
            <IonLabel>9:16 Aspect Ratio Calculator</IonLabel>
          </IonItem>
        </IonList>
        <IonModal id="modal" ref={modal}>
          <Settings onClose={() => modal.current?.dismiss()} />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Index;
